


import { Component, Prop, Vue } from 'vue-property-decorator';
import { TNewsIssue } from '@/_types/news-issue.type';
import FormFileUploader from '@/_modules/controls/components/form-file-uploader/form-file-uploader.vue';
import newsApi, {
  TCreateEditNewsIssueQueryParams,
  TCreateNewsIssueParams,
  TEditNewsIssueParams
} from '@/_api/news.api';
import IconSquareEdit from '@/_modules/icons/components/icon-square-edit.vue';
import IconSquareDelete from '@/_modules/icons/components/icon-square-delete.vue';
import IconPlus from '@/_modules/icons/components/icon-plus.vue';
import { TFile } from '@/_types/file.type';

@Component({
  components: {
    FormFileUploader,
    IconSquareEdit,
    IconSquareDelete,
    IconPlus,
  }
})
export default class CabinetNewsEditForm extends Vue {

  public newsForm: TCreateEditNewsIssueQueryParams = {
    title: '',
    sorting: 0,
    text: '',
    photos: [],
    is_published: false,
  }

  public isNewsImageLoading: boolean = false;
  public newsImageError: string = '';
  public newsImageFile: TFile = {
    url: '',
    filename: '',
  };

  @Prop({ type: Object, default: null })
  public readonly editingNewsIssue: TNewsIssue;

  public mounted(): void {
    this.initEditingEntity();
  }

  private initEditingEntity(): void {
    if (this.editingNewsIssue && this.editingNewsIssue.id) {
      const newObj = {...this.editingNewsIssue};
      delete newObj.id;
      delete newObj.created_at;
      this.newsForm = {...newObj};
      if (this.newsForm.photos && this.newsForm.photos.length) {
        this.newsImageFile.url = this.newsForm.photos[0];
      }
    }
  }

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  public get isEditMode(): boolean {
    if (this.editingNewsIssue && this.editingNewsIssue.id) {
      return true;
    }
    return false;
  }

  private onSubmit(): void {

    // TODO: show error on required field «Name»
    if (this.isSubmitDisabled) {
      return;
    }

    if (this.isEditMode) {
      this.editGivenIssue();
    } else {
      this.createNewIssue();
    }

  }

  private async createNewIssue(): Promise<void> {

    const payload: TCreateNewsIssueParams = {
      eventId: this.eventId,
      data: this.newsForm,
    };

    await newsApi.createNewsIssue(payload);
    // TODO: error handling

    this.$emit('success');
  }

  private async editGivenIssue(): Promise<void> {

    const payload: TEditNewsIssueParams = {
      eventId: this.eventId,
      newsId: this.editingNewsIssue.id,
      data: {...this.newsForm},
    };

    await newsApi.editNewsIssue(payload);
    // TODO: error handling

    this.$emit('success');
  }

  public get isSubmitDisabled(): boolean {
    return !this.newsForm.title;
  }

  private setIsNewsImageLoading(value: TFile): void {
    if (value && value.url === '') {
      this.isNewsImageLoading = true;
      return;
    } else if (value.url) {
      this.isNewsImageLoading = false;
      this.newsForm.photos = [value.url];
    }
    this.isNewsImageLoading = false;
  }

  private setNewsImageError(err: string): void {
    this.newsImageError = err || '';
    setTimeout(() => {
      this.newsImageError = '';
      this.isNewsImageLoading = false;
    }, 3000);
  }

  private deleteNewsImage(): void {
    this.newsForm.photos = [];
    this.newsImageFile.url = '';
  }

  private onNewsImageInput(value: TFile): void {
    this.newsForm.photos = [value.url];
    this.isNewsImageLoading = false;
  }

}
