


import { Component, Prop, Vue } from 'vue-property-decorator';
import { TNewsIssue } from '@/_types/news-issue.type';
import IconSquareEdit from '@/_modules/icons/components/icon-square-edit.vue';
import IconSquareDelete from '@/_modules/icons/components/icon-square-delete.vue';
import IconNotify from '@/_modules/icons/components/icon-notify.vue';
import eventDiscoveryService from '@/_services/event-discovery.service';
import { TDiscoveryNewsArticleNotificationResponse } from '@/_types/discovery/discovery-news-article-notification-response.type';

const DEFAULT_AVATAR_NEWS = require('@/assets/images/default-news-image-60x60.svg');

@Component({
  components: {
    IconSquareEdit,
    IconSquareDelete,
    IconNotify,
  }
})
export default class CabinetNewsItem extends Vue {

  @Prop({type: Object})
  public readonly newsIssue: TNewsIssue;

  @Prop({type: Number})
  public readonly positionNumber: number;

  public isNewsIssuePublished: boolean = false;
  public isNotifyActionThrottled: boolean = false;

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  public get isNotifyActionDisabled(): boolean {
    if (!this.newsIssue) {
      return false;
    }
    return !this.newsIssue.is_published || this.isNotifyActionThrottled;
  }

  public mounted(): void {
    this.isNewsIssuePublished = this.newsIssue.is_published;
  }

  private onEditNewsIssueClick(): void {
    this.$emit('edit');
  }

  private onDeleteNewsIssueClick(): void {
    this.$emit('delete');
  }

  private getNewsImageStyles(issue: TNewsIssue): { [key: string]: string } {
    let backgroundImageUrl = DEFAULT_AVATAR_NEWS;
    if (issue && issue.photos && issue.photos.length) {
      backgroundImageUrl = issue.photos[0];
    }
    return {
      'background-image': 'url(' + backgroundImageUrl + ')',
    };
  }

  private onVisibilityChange(): void {
    this.$emit('visibilityChanged');
  }

  private onNotifyClick(): void {
    if (!this.newsIssue.is_published) {
      return;
    }
    this.throttleNotifyButton();
    const context: TDiscoveryNewsArticleNotificationResponse = {
      eventId: this.eventId,
      newsId: this.newsIssue.id,
      newsTitle: this.newsIssue.title,
    };
    eventDiscoveryService.notifyAboutNewsArticle(context);
    this.delayedUnThrottleNotifyButton();
  }

  private throttleNotifyButton(): void {
    this.isNotifyActionThrottled = true;
  }

  private unThrottleNotifyButton(): void {
    this.isNotifyActionThrottled = false;
  }

  private delayedUnThrottleNotifyButton(): void {
    setTimeout(this.unThrottleNotifyButton, 20000);
  }

}
