import {AxiosRequestConfig, AxiosResponse, CancelToken} from 'axios';
import BaseApi from '@/_types/api/base.api.class';
import { TApiListResponse } from '@/_types/api/api-list-response.type';
import { TNewsIssue } from '@/_types/news-issue.type';

export type TGetEventNewsParams = {
  eventId: number;
  onlyPublished?: boolean;
  offset?: number;
  limit?: number;
}

export type TGetNewsIssueParams = {
  eventId: number;
  newsId: number;
}

export type TCreateNewsIssueParams = {
  eventId: number;
  data: TCreateEditNewsIssueQueryParams;
}

export type TCreateEditNewsIssueQueryParams = {
  title: string;
  text: string;
  sorting: number;
  is_published: boolean;
  photos: string[];
}

export type TEditNewsIssueParams = {
  eventId: number;
  newsId: number;
  data: TCreateEditNewsIssueQueryParams;
}

export class NewsApi extends BaseApi {

  public async getEventNews(params: TGetEventNewsParams, cancelToken?: CancelToken): Promise<TNewsIssue[]> {
    const { eventId, onlyPublished, limit, offset } = params;
    const config: AxiosRequestConfig = {
      url: `/event/${eventId}/news/list`,
      method: 'GET',
      cancelToken,
      params: {
        only_published: onlyPublished,
        offset,
        limit
      }
    };

    let response;
    try {
      response = await this.axios.request<TApiListResponse<TNewsIssue>>(config);
    } catch (error) {
      throw this.responseError(error);
    }

    return response.data.List;
  }

  public async getNewsIssue(params: TGetNewsIssueParams, cancelToken?: CancelToken): Promise<TNewsIssue> {
    const { eventId, newsId } = params;
    const config: AxiosRequestConfig = {
      url: `/event/${eventId}/news/${newsId}`,
      method: 'GET',
      cancelToken,
    };

    let response;
    try {
      response = await this.axios.request<TNewsIssue>(config);
    } catch (error) {
      throw this.responseError(error);
    }

    return response.data;
  }

  public async createNewsIssue(params: TCreateNewsIssueParams): Promise<TNewsIssue> {

    const { eventId, data } = params;
    const config: AxiosRequestConfig = {
      url: `event/${eventId}/news`,
      method: 'POST',
      params: data
    };

    let response: AxiosResponse;
    try {
      response = await this.axios.request<TNewsIssue>(config);
    } catch (error) {
      throw this.responseError(error);
    }

    return response.data;
  }

  public async editNewsIssue(params: TEditNewsIssueParams): Promise<boolean> {

    const { eventId, newsId, data } = params;
    const config: AxiosRequestConfig = {
      url: `event/${eventId}/news/${newsId}`,
      method: 'PATCH',
      params: data
    };

    let response: AxiosResponse;
    let result = false;
    try {
      response = await this.axios.request<any>(config);
      result = response && response.status === 202;
    } catch (error) {
      throw this.responseError(error);
    }

    return result;
  }

  public async deleteNewsIssue(params: TGetNewsIssueParams): Promise<void> {
    const { eventId, newsId } = params;

    const config: AxiosRequestConfig = {
      url: `event/${eventId}/news/${newsId}`,
      method: 'DELETE',
    };

    try {
      await this.axios.request<any>(config);
    } catch (error) {
      throw this.responseError(error);
    }

  }

  public async postNewsNotification(params: TGetNewsIssueParams): Promise<boolean> {

    const { eventId, newsId } = params;
    const config: AxiosRequestConfig = {
      url: `event/${eventId}/news/${newsId}`,
      method: 'POST',
    };

    let response: AxiosResponse;
    let result = false;
    try {
      response = await this.axios.request<any>(config);
      result = response && response.status === 202;
    } catch (error) {
      throw this.responseError(error);
    }

    return result;
  }

}

const newsApi = new NewsApi();
export default newsApi;
